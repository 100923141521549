import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, FormControlLabel, Checkbox, Button, Tooltip, IconButton, Icon, TextField } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useLocation, Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';
import EmployeeToPrint from '../components/employee-to-print';
import EmployeeSede from '../components/employee-sede';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%'
    },
    employeesToPrint: {
        display: 'none'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    print: {
        marginRight: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none'
        }
    }
}));

const firstSede = { id: 0, name: 'Choose a sede' };

const Employees = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [modalState, setModalState] = useState({ visible: false, employee: {} });
    const [state, setState] = useState({
        loading: true,
        sedes: [],
        employees: [],
        employee: {
            idemployee: 0,
            code: '',
            name: '',
            base_rate: 0,
            indicator: 0,
            active: true,
            idsede: 0,
            sede: firstSede,
            is_supplier: false,
            payment: true,
            regular_overtime: true,
            bonus_payment: true
        },
        employeesToPrint: [],
        permissions: {}
    });

    const printRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });

    useEffect(() => {
        window.document.title = 'Employees';

        validatePermissions();
    }, []);

    useEffect(() => {
        if (state.employeesToPrint.length > 0) {
            handlePrint();
        }
    }, [state.employeesToPrint]);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/employees');
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, employees: response?.data ?? [], sedes: response?.sedes ?? [] }));
        } else {
            console.log('[Employees] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, employee: { ...prev.employee, [target.name]: target.value } }));

    const handleCheck = ({ target }) => setState(prev => ({ ...prev, employee: { ...prev.employee, [target.name]: target.checked } }));

    const validateForm = (type) => {
        const { idemployee } = state.employee;

        switch (type) {
            case 'update':
                const data = state.employees.filter(value => value.idemployee === idemployee);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.employee);
                    if (before !== after) {
                        return true;
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some employee', 'error');
                    return false;
                }

            case 'delete':
                if (idemployee === 0) {
                    showToast('Select some employee');
                    return false;
                }
                const cond = window.confirm('Are you sure you want to delete this employee?');

                return Boolean(idemployee && cond);
            default:
                break;
        }
    }

    const clear = () => {
        setState(prev => ({
            ...prev,
            employee: {
                ...prev.employee,
                idemployee: 0,
                code: '',
                name: '',
                base_rate: 0,
                active: true,
                idsede: 0,
                sede: firstSede,
                is_supplier: false,
                payment: true,
                regular_overtime: true,
                bonus_payment: true
            }
        }));
    }

    const store = async () => {
        showLoader(true);
        const response = await post('/employee/store', state.employee);
        if (response.status === 200) {
            setState(prev => ({ ...prev, employees: response?.data ?? prev.employees }));
            showToast(response.message);
        } else {
            console.log('[Employees] store error', response);
            helpers.failRequest(response, showToast, signOut);
            showLoader(false);

            return false;
        }
        showLoader(false);
    }

    const update = async () => {
        const { idemployee } = state.employee;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/employee/${idemployee}/update`, state.employee);
            if (response.status === 200) {
                setState(prev => ({ ...prev, employees: response?.data ?? prev.employees }));
                showToast(response.message);
            } else {
                console.log('[Employees] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idemployee } = state.employee;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/employee/${idemployee}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, employees: response?.data ?? prev.employees }));
                showToast(response.message);
            } else {
                console.log('[Employees] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const getEmployeesToPrint = async () => {
        const ids = selectedEmployees;

        if (ids.length < 1) {
            showToast('Select some employee', 'error');
            return null;
        }

        let form = { idemployee: ids.join('-') };

        showLoader(true);
        const response = await get('/employees/print', form);
        if (response.status === 200) {
            setState(prev => ({ ...prev, employeesToPrint: response?.data ?? [] }));

            if (response.data?.length === 0) showToast('No employees to print', 'warning');
        } else {
            console.log('[Employees] getEmployeesToPrint error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const valueGetter = ({ value }) => value ? '✓' : '✗';

    const getSede = ({ value }) => value?.name ?? 'N/A';

    const getSalaryPercent = ({ value }) => `${value} %`;

    const renderCell = ({ row }) => {
        return (
            <>
                <Tooltip title='Split salary' placement='top'>
                    <IconButton onClick={() => setModalState({ visible: true, employee: row })}>
                        <Icon>{'call_split'}</Icon>
                    </IconButton>
                </Tooltip>
                <Link to={`/history/employees/${row.idemployee}`} target='_blank'>
                    <Tooltip title='Show history' placement='top'>
                        <IconButton>
                            <Icon>{'history'}</Icon>
                        </IconButton>
                    </Tooltip>
                </Link>
            </>
        );
    }

    const columns = [
        { field: 'code', headerName: 'Code', flex: 0.8, minWidth: 100 },
        { field: 'name', headerName: 'Name', flex: 2, minWidth: 250 },
        { field: 'base_rate', headerName: 'Base rate', flex: 1, minWidth: 120 },
        { field: 'indicator', headerName: 'Indicator', flex: 1, minWidth: 120 },
        { field: 'sede', headerName: 'Sede', flex: 1, minWidth: 120, valueGetter: getSede },
        { field: 'active', headerName: 'Active', flex: 1, minWidth: 100, valueGetter },
        { field: 'is_supplier', headerName: 'Supplier', flex: 1, minWidth: 100, valueGetter },
        { field: 'payment', headerName: 'Payment', flex: 1, minWidth: 100, valueGetter },
        { field: 'regular_overtime', headerName: 'Regular OT', flex: 1, minWidth: 100, valueGetter },
        { field: 'bonus_payment', headerName: 'Bonus payment', flex: 1, minWidth: 100, valueGetter },
        { field: 'salary_percent', headerName: 'Salary percent', flex: 1, minWidth: 100, valueGetter: getSalaryPercent },
        { field: 'actions', headerName: 'Actions', flex: 0.9, minWidth: 100, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Employees' />
            <div>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <FormGroup>
                            <FormLabel>{'Code'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Code'
                                    name='code'
                                    value={state.employee.code}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <FormGroup>
                            <FormLabel>{'Name'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='text'
                                    placeholder='Name'
                                    name='name'
                                    value={state.employee.name}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormGroup>
                            <FormLabel>{'Base rate'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Base rate'
                                    name='base_rate'
                                    value={state.employee.base_rate}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormGroup>
                            <FormLabel>{'Indicator'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    autoComplete='off'
                                    type='number'
                                    placeholder='Indicator'
                                    name='indicator'
                                    value={state.employee.indicator}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormGroup>
                            <FormLabel>{'Sede'}</FormLabel>

                            <Autocomplete
                                options={state.sedes}
                                value={state.employee.sede}
                                getOptionLabel={option => option?.name ?? ''}
                                getOptionSelected={(option, value) => option.id === value.id}
                                onChange={(event, sede) => {
                                    setState(prev => ({ ...prev, employee: { ...prev.employee, sede } }));
                                }}
                                renderInput={params => <TextField {...params} placeholder='Sede' />}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={6} md={2}>
                        <FormControlLabel
                            checked={state.employee.active}
                            control={<Checkbox color='primary' />}
                            label='Active'
                            labelPlacement='top'
                            name='active'
                            onChange={handleCheck}
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FormControlLabel
                            checked={state.employee.is_supplier}
                            control={<Checkbox color='primary' />}
                            label='Is supplier'
                            labelPlacement='top'
                            name='is_supplier'
                            onChange={handleCheck}
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FormControlLabel
                            checked={state.employee.payment}
                            control={<Checkbox color='primary' />}
                            label='Payment'
                            labelPlacement='top'
                            name='payment'
                            onChange={handleCheck}
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FormControlLabel
                            checked={state.employee.regular_overtime}
                            control={<Checkbox color='primary' />}
                            label='Regular overtime'
                            labelPlacement='top'
                            name='regular_overtime'
                            onChange={handleCheck}
                        />
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FormControlLabel
                            checked={state.employee.bonus_payment}
                            control={<Checkbox color='primary' />}
                            label='Bonus payment'
                            labelPlacement='top'
                            name='bonus_payment'
                            onChange={handleCheck}
                        />
                    </Grid>
                </Grid>
            </div>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            <div className={classes.buttonContainer}>
                <div>
                    <Button
                        variant='contained'
                        className={classes.print}
                        onClick={getEmployeesToPrint}
                    >
                        {'Print barcodes'}
                    </Button>
                </div>
                {
                    state.permissions.delete_attr &&
                    <Trash table='employees' id='idemployee' onRefresh={makeRequest} />
                }
            </div>

            <div className={classes.tableContainer}>
                <DataGrid
                    autoHeight
                    pageSize={45}
                    density='compact'
                    columns={columns}
                    rows={state.employees}
                    getRowId={row => row.idemployee}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, employee: row }))}
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionModelChange={array => setSelectedEmployees(array)}
                />
            </div>

            <EmployeeSede open={modalState.visible} onClose={() => setModalState({ visible: false, employee: {} })} employee={modalState.employee} sedes={state.sedes} />

            <div className={classes.employeesToPrint}>
                <div ref={printRef}>
                    <EmployeeToPrint data={state.employeesToPrint} />
                </div>
            </div>
        </div>
    );
}

export default Employees;