import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import dayjs from 'dayjs';

import themeColors from '../../assets/colors';

import '../../assets/css/print.css';

const Logo = require('../../assets/images/logo-full.png').default;

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0
    },
    container: {
        display: 'block',
        pageBreakAfter: 'always'
    },
    logo: {
        maxHeight: 80
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 10
    },
    subheaderContainer: {
        display: 'block',
        pageBreakInside: 'avoid'
    },
    NMContainer: {
        display: 'block',
        pageBreakInside: 'auto'
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        backgroundColor: '#F2F2F2',
        padding: 5,
        fontSize: 10
    },
    textContainer: {
        color: '#000',
        padding: 5
    },
    detail: {
        backgroundColor: '#fff'
    },
    total: {
        backgroundColor: '#F2F2F2'
    },
    footer: {
        backgroundColor: '#6E6E6E',
        color: '#fff'
    },
    totalContainer: {
        color: '#fff',
        padding: 5
    },
    totalTable: {
        backgroundColor: themeColors.app.main
    }
}));

const Text = ({ title, color, header }) => {
    const classes = useStyles();

    return <TableCell
        align='center'
        style={{ backgroundColor: color ? color : 'transparent', fontSize: header ? 7 : 10 }}
        className={classes.textContainer}
    >
        {title}
    </TableCell>
}

const Total = ({ title, header }) => {
    const classes = useStyles();

    return <TableCell
        align='center'
        style={{ fontSize: header ? 7 : 10 }}
        className={classes.totalContainer}
    >
        {title}
    </TableCell>
}

const EmployeePayToPrint = ({ data }) => {

    const classes = useStyles();

    const getTotalsM = header => {
        let hours = '0', regular_hours = '0', regular_hours_pay = '0', overtime_hours = '0', overtime_hours_pay = '0', total_qty = '0', base_pay = '0', real_pay = '0', bonus = '0';

        header.subheadersM?.map(subheader => {
            hours = (Number(hours) + Number(subheader.hours)).toFixed(2);
            regular_hours = (Number(regular_hours) + Number(subheader.regular_hours)).toFixed(2);
            regular_hours_pay = (Number(regular_hours_pay) + Number(subheader.regular_hours_pay)).toFixed(2);
            overtime_hours = (Number(overtime_hours) + Number(subheader.overtime_hours)).toFixed(2);
            overtime_hours_pay = (Number(overtime_hours_pay) + Number(subheader.overtime_hours_pay)).toFixed(2);
            base_pay = (Number(base_pay) + Number(subheader.base_pay)).toFixed(2);
            bonus = (Number(bonus) + Number(subheader.bonus)).toFixed(2);
            real_pay = (Number(real_pay) + Number(subheader.real_pay)).toFixed(2);

            subheader.detail?.map(item => {
                total_qty = Number(total_qty) + Number(item.total_qty);

                return false;
            });

            return false;
        });

        return [hours, regular_hours, `$ ${regular_hours_pay}`, overtime_hours, `$ ${overtime_hours_pay}`, total_qty, `$${base_pay}`, `$${bonus}`, `$${real_pay}`];
    }

    const getTotalsNM = subheadersNM => {
        let hours = 0, reg = 0, regPay = 0, ot1 = 0, ot1Pay = 0, ot2 = 0, ot2Pay = 0, total = 0;

        subheadersNM?.map(subheader => {
            subheader?.detail?.map(item => {
                hours = (Number(hours) + Number(item.hours)).toFixed(2);
                reg = (Number(reg) + Number(item.regular_hours)).toFixed(2);
                regPay = (Number(regPay) + Number(item.regular_hours_pay)).toFixed(2);
                ot1 = (Number(ot1) + Number(item.overtime_week)).toFixed(2);
                ot1Pay = (Number(ot1Pay) + Number(item.overtime_week_pay)).toFixed(2);
                ot2 = (Number(ot2) + Number(item.overtime_holiday)).toFixed(2);
                ot2Pay = (Number(ot2Pay) + Number(item.overtime_holiday_pay)).toFixed(2);
                total = (Number(total) + Number(item.total)).toFixed(2);

                return null;
            });

            return null;
        });

        return [hours, reg, `$ ${regPay}`, ot1, `$ ${ot1Pay}`, ot2, `$ ${ot2Pay}`, `$ ${total}`];
    }

    const getTotals = header => {
        let hours = 0, regular_hours = 0, regular_hours_pay = 0, overtime_hours = 0, overtime_hours_pay = 0;

        header.subheadersM?.map(subheader => {
            hours = (Number(hours) + Number(subheader.hours)).toFixed(2);
            regular_hours = (Number(regular_hours) + Number(subheader.regular_hours)).toFixed(2);
            regular_hours_pay = (Number(regular_hours_pay) + Number(subheader.regular_hours_pay)).toFixed(2);
            overtime_hours = (Number(overtime_hours) + Number(subheader.overtime_hours)).toFixed(2);
            overtime_hours_pay = (Number(overtime_hours_pay) + Number(subheader.overtime_hours_pay)).toFixed(2);

            return false;
        });

        header.subheadersNM?.map(subheader => {
            subheader?.detail?.map(item => {
                hours = (Number(hours) + Number(item.hours)).toFixed(2);
                regular_hours = (Number(regular_hours) + Number(item.regular_hours)).toFixed(2);
                regular_hours_pay = (Number(regular_hours_pay) + Number(item.regular_hours_pay)).toFixed(2);
                overtime_hours = (Number(overtime_hours) + Number(item.overtime_week)).toFixed(2);
                overtime_hours_pay = (Number(overtime_hours_pay) + Number(item.overtime_week_pay)).toFixed(2);

                return null;
            });

            return null;
        });

        return [hours, regular_hours, `$ ${regular_hours_pay}`, overtime_hours, `$ ${overtime_hours_pay}`, `$ ${header.total}`]
    }

    const getProduct = txt => {
        const limit = 32;
        const cont = txt.length;

        return (cont > limit) ? txt.substr(0, limit - 3).concat('...') : txt.substr(0, limit);
    }

    return (
        <div className={classes.root}>
            <div>
                {
                    data.map(header =>
                        <div key={header.idemployee_pay_header} className={classes.container}>
                            <div className={classes.header}>
                                <div className={classes.description}>
                                    <span><b>{`Date: `}</b>{dayjs().format('MM/DD/YYYY HH:mm')}</span>
                                    <span><b>{`Year-Week: `}</b>{header.week}</span>
                                    <span><b>{`Employee: `}</b>{header.employee.name}</span>
                                    <span><b>{`Base rate: `}</b>{`$ ${header.base_rate}`}</span>
                                    <span><b>{`Total hours: `}</b>{header.hours}</span>
                                    <span><b>{`Total paid: `}</b>{`$ ${header.total}`}</span>
                                </div>
                                <img className={classes.logo} src={Logo} alt='logo cfg' />
                            </div>

                            <TableContainer component={Box}>
                                <Table size='small' className={classes.totalTable}>
                                    <TableHead>
                                        <TableRow>
                                            <Total header title='Total hours' />
                                            <Total header title='Regular hours' />
                                            <Total header title='Regular hours $' />
                                            <Total header title='OT hours' />
                                            <Total header title='OT hours $' />
                                            <Total header title='Total paid' />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow >
                                            {
                                                getTotals(header).map((item, i) => <Total key={i.toString()} title={item} />)
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <h3>{'Payment (M)'}</h3>
                            {
                                header.subheadersM.map(subheader =>
                                    <div key={subheader.idemployee_pay_subheader} className={classes.subheaderContainer}>
                                        <div className={classes.tableHeader}>
                                            <span><b>{`Date: `}</b>{dayjs(subheader.date).format('MM/DD/YYYY')}</span>
                                            <span><b>{'Regular hours: '}</b>{subheader.regular_hours}</span>
                                            <span><b>{'OT hours: '}</b>{subheader.overtime_hours}</span>
                                        </div>

                                        <TableContainer component={Box}>
                                            <Table size='small' className={classes.detail}>
                                                <TableHead>
                                                    <TableRow>
                                                        <Text header title='Position' />
                                                        <Text header title='Product code' />
                                                        <Text header title='Hours pay' color='#DDF5FD' />
                                                        {
                                                            Boolean(Number(header.overtime_base_rate)) &&
                                                            <Text header title='OT pay' color='#ECCEF5' />
                                                        }
                                                        <Text header title='Product rate' />
                                                        <Text header title='Total qty' />
                                                        <Text header title='Base pay' color='#DDF5FD' />
                                                        <Text header title='Real pay' color='#EDFBDB' />
                                                        <Text header title='Bonus' />
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        subheader.detail.map(item =>
                                                            <TableRow key={item.idemployee_pay}>
                                                                <Text title={item.position} />
                                                                <Text title={getProduct(`${item.product.code} - ${item.product.description}`)} />
                                                                <Text title={item.hours_break !== '0.00' ? `- $ ${(item.hours_break * header.base_rate).toFixed(2)} (${item.hours_break})` : '-'} color='#DDF5FD' />
                                                                {
                                                                    Boolean(Number(header.overtime_base_rate)) &&
                                                                    <Text title={`-`} color='#ECCEF5' />
                                                                }
                                                                <Text title={`$ ${item.product_rate}`} />
                                                                <Text title={item.total_qty} />
                                                                <Text title={`$ ${item.base_pay}`} color='#DDF5FD' />
                                                                <Text title={`-`} color='#EDFBDB' />
                                                                <Text title={`-`} />
                                                            </TableRow>
                                                        )
                                                    }
                                                    <TableRow className={classes.total}>
                                                        <Text title={'Totals:'} />
                                                        <TableCell colSpan={1} />
                                                        <Text title={<b>{`$ ${subheader.regular_hours_pay}`}</b>} color='#DDF5FD' />
                                                        {
                                                            Boolean(Number(header.overtime_base_rate)) &&
                                                            <Text title={<b>{`$ ${subheader.overtime_hours_pay}`}</b>} color='#ECCEF5' />
                                                        }
                                                        <TableCell colSpan={1} />
                                                        <TableCell colSpan={1} />
                                                        <Text title={<b>{`$ ${subheader.base_pay}`}</b>} color='#DDF5FD' />
                                                        <Text title={<b>{`$ ${subheader.real_pay}`}</b>} color='#EDFBDB' />
                                                        <Text title={<b>{`$ ${subheader.bonus}`}</b>} />
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                )
                            }
                            <TableContainer component={Box}>
                                <Table size='small' className={classes.footer}>
                                    <TableHead>
                                        <TableRow>
                                            <Total header title='Total hours' />
                                            <Total header title='Regular hours' />
                                            <Total header title='Regular hours $' />
                                            <Total header title='OT hours' />
                                            <Total header title='OT hours $' />
                                            <Total header title='Total piece qty' />
                                            <Total header title='Piece pay' />
                                            <Total header title='Bonus' />
                                            <Total header title='Total paid' />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow >
                                            {
                                                getTotalsM(header).map((item, i) => <Total key={i.toString()} title={item} />)
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <h3>{'Payment (NM)'}</h3>
                            {
                                header.subheadersNM.map(subheader => {
                                    return (
                                        <div key={subheader.idemployee_pay_subheader_nm} className={classes.NMContainer}>
                                            <div className={classes.tableHeader}>
                                                <span><b>{'Total hours: '}</b>{subheader.hours}</span>
                                                <span><b>{'Total paid: '}</b>{`$ ${subheader.total}`}</span>
                                            </div>
                                            <TableContainer>
                                                <Table size='small' className={classes.detail}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <Text header title='Date' />
                                                            <Text header title='Total hours' />
                                                            <Text header title='Regular hours' />
                                                            <Text header title='Regular hours $' />
                                                            <Text header title='OT hours' />
                                                            <Text header title='OT hours $' />
                                                            <Text header title='Holiday hours' />
                                                            <Text header title='Holiday hours $' />
                                                            <Text header title='Total paid' />
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            subheader.detail.map(item =>
                                                                <TableRow key={item.idemployee_pay_nm}>
                                                                    <Text title={dayjs(item.date).format('MM/DD/YYYY')} />
                                                                    <Text title={item.hours} />
                                                                    <Text title={item.regular_hours} />
                                                                    <Text title={`$ ${item.regular_hours_pay}`} />
                                                                    <Text title={item.overtime_week} />
                                                                    <Text title={`$ ${item.overtime_week_pay}`} />
                                                                    <Text title={item.overtime_holiday} />
                                                                    <Text title={`$ ${item.overtime_holiday_pay}`} />
                                                                    <Text title={`$ ${item.total}`} />
                                                                </TableRow>
                                                            )
                                                        }
                                                        <TableRow className={classes.total}>
                                                            <Text title={'Totals:'} />
                                                            <Text title={<b>{subheader.hours}</b>} />
                                                            <Text title={<b>{subheader.regular_hours}</b>} />
                                                            <Text title={<b>{`$ ${subheader.regular_hours_pay}`}</b>} />
                                                            <Text title={<b>{subheader.overtime_week}</b>} />
                                                            <Text title={<b>{`$ ${subheader.overtime_week_pay}`}</b>} />
                                                            <Text title={<b>{subheader.overtime_holiday}</b>} />
                                                            <Text title={<b>{`$ ${subheader.overtime_holiday_pay}`}</b>} />
                                                            <Text title={<b>{`$ ${subheader.total}`}</b>} />
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    );
                                })
                            }
                            <TableContainer component={Box}>
                                <Table size='small' className={classes.footer} style={{ pageBreakAfter: 'always' }}>
                                    <TableHead>
                                        <TableRow>
                                            <Total header title='Total hours' />
                                            <Total header title='Regular hours' />
                                            <Total header title='Regular hours $' />
                                            <Total header title='OT hours' />
                                            <Total header title='OT hours $' />
                                            <Total header title='Holiday hours' />
                                            <Total header title='Holiday hours $' />
                                            <Total header title='Total paid' />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow >
                                            {
                                                getTotalsNM(header.subheadersNM).map((item, i) => <Total key={i.toString()} title={item} />)
                                            }
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default EmployeePayToPrint;