import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, Typography, Button, MenuItem } from '@material-ui/core';

import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '80%',
        height: '50%',
        borderRadius: 10,
    },
    bottom: {
        display: 'flex',
        alignSelf: 'flex-end',
    }
}));

const AvoidedBot = ({ open, onClose, data }) => {

    const classes = useStyles();

    const employeeErr = data?.employee?.map(item => <MenuItem key={item}>{item}</MenuItem>)
    const actionErr = data?.employee_hour_action?.map(item => <MenuItem key={item}>{item}</MenuItem>)
    const cc2Err = data?.cost_center_2?.map(item => <MenuItem key={item}>{item}</MenuItem>)
    const cc3Err = data?.cost_center_3?.map(item => <MenuItem key={item}>{item}</MenuItem>)

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.root}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Avoided data'}</Typography>
                <div style={{ overflow: 'auto' }}>

                    {data?.employee? 
                    <Typography variant='h4'>{`Employee codes avoided: ${data?.employee.length}`}</Typography> : <></>}
                    {data?.employee ? employeeErr : <></>}

                    {data?.employee_hour_action? 
                    <Typography variant='h4'>{`Employee hour actions avoided: ${data?.employee_hour_action.length}`}</Typography> : <></>}
                    {data?.employee_hour_action ? actionErr : <></>}

                    {data?.cost_center_2? 
                    <Typography variant='h4'>{`Cost center 2 avoided: ${data?.cost_center_2.length}`}</Typography> : <></>}
                    {data?.cost_center_2 ? cc2Err : <></>}

                    {data?.cost_center_3? 
                    <Typography variant='h4'>{`Cost center 3 avoided: ${data?.cost_center_3.length}`}</Typography> : <></>}
                    {data?.cost_center_3 ? cc3Err : <></>}
                
                </div>
                <div className={classes.bottom}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={onClose}
                    >
                        {'Close'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default AvoidedBot;