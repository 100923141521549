import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, Button, TextField, Tooltip, IconButton, Icon } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import dayjs from 'dayjs';
import XLSX from 'xlsx';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Avoided from '../components/avoided';
import AvoidedBot from '../components/avoidedBot';
import DateRange from '../../common/date-range';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { del, get, post } from '../../utils/api-services';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    import: {
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    delete: {
        marginLeft: 20,
        backgroundColor: '#dc3545',
        borderColor: '#dc3545',
        '&:hover': {
            backgroundColor: '#dc3545',
            borderColor: '#dc3545',
            boxShadow: 'none',
        }
    },
    search: {
        marginLeft: 20,
        backgroundColor: '#198754',
        borderColor: '#198754',
        '&:hover': {
            backgroundColor: '#198754',
            borderColor: '#198754',
            boxShadow: 'none',
        }
    },
    acceptButton: {
        marginTop: 10,
        backgroundColor: '#198754',
        borderColor: '#198754',
        '&:hover': {
            backgroundColor: '#198754',
            borderColor: '#198754',
            boxShadow: 'none',
        }
    },
    tableContainer: {
        width: '100%',
    },
    tabBox: {
        backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
}));

//Functions for tabs
function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{bgcolor: 'rgba(0, 0, 0, 0.1)'}} p={2}>
            <div>{children}</div>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
      return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }
//------------------------------------

const EmployeeHour = () => {
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [open, setOpen] = useState(false);
    const [logOpen, setLogOpen] = useState(false);
    const [avoidedBotData, setAvoidedBotData] = useState({});
    const [tabValue, setTabValue] = React.useState(0);

    const [state, setState] = useState({
        loading: true,
        employees: [],
        data: [],
        requestData: [],
        filter: {
            startDate: dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
            dateRange: `${dayjs().subtract(2, 'day').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`,
            employee: { id: 0, name: 'All employees' }
        },
        rangeModal: {
            open: false,
            range: [
                {
                    startDate: new Date(dayjs().subtract(2, 'day').toDate()),
                    endDate: new Date(),
                    key: 'main'
                }
            ]
        },
        requestRangeModal: {
            open: false,
            dateRange: `${dayjs().subtract(7, 'day').format('MM/DD/YYYY')} - ${dayjs().format('MM/DD/YYYY')}`,
            startDate: `${new Date(dayjs().subtract(7, 'day').toDate())}`,
            endDate: `${new Date(dayjs().toDate())}`,
            range: [
                {
                    startDate: new Date(dayjs().subtract(7, 'day').toDate()),
                    endDate: new Date(dayjs().toDate()),
                    key: 'main'
                }
            ]
        },
        fileData: [],
        avoided: [],
        botAvoided: [],
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Employee hour';

        validatePermissions();
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
      };

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/employeeHour', state.filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, loading: false, data: response.data, employees: response.employees }));
        } else {
            console.log('[EmployeeHour] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleFile = async ({ target }) => {
        showLoader(true);

        try {
            const file = target.files[0];
            const buffer = await file.arrayBuffer();
            const excel = XLSX.read(buffer, { type: 'array', cellDates: true });
            const sheet = excel.Sheets[excel.SheetNames[0]];
            const data = XLSX.utils.sheet_to_json(sheet, { dateNF: 'd/m/yyyy' });

            if (Boolean(data[0]['Code'])) {
                setState(prev => ({ ...prev, fileData: data, avoided: [] }));
            } else {
                showToast('File format not supported', 'error');
            }

        } catch (e) {
            console.log(e);
            showToast('Something went wrong', 'error');
        }
        showLoader(false);
    }

    const handleDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                dateRange,
                startDate: dayjs(startDate.toISOString()).format('YYYY-MM-DD'),
                endDate: dayjs(endDate.toISOString()).format('YYYY-MM-DD')
            },
            rangeModal: { ...prev.rangeModal, range: [item.main] }
        }));
    }

    const handleRequestDateChange = item => {
        const { startDate, endDate } = item.main;
        const dateRange = `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')} - ${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`;
        setState(prev => ({
            ...prev,
            requestRangeModal: { open: true, range: [item.main], dateRange: dateRange, startDate: `${dayjs(startDate.toISOString()).format('MM/DD/YYYY')}`, endDate:`${dayjs(endDate.toISOString()).format('MM/DD/YYYY')}`}
        }));
    }

    const handleModal = () => setState(prev => ({ ...prev, rangeModal: { ...prev.rangeModal, open: !prev.rangeModal.open } }));

    const handleRequestModal = () => setState(prev => ({ ...prev, requestRangeModal: { ...prev.requestRangeModal, open: !prev.requestRangeModal.open } }));

    const handleAutocomplete = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const importPaylocityRequestData = async () => {
        showLoader(true);
        const response = await get('/botRequest');
        if (response.status === 200) {
            setState(prev => ({ ...prev, requestData: response.data, botAvoided: response.avoided ?? [] }));
        } else {
            console.log('[EmployeeHour] importPaylocityRequestData error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const createPaylocityRequest = async () =>{
        const { requestRangeModal } = state;
        showLoader(true);
        const response = await post('/botRequest/store', { start_date: requestRangeModal.startDate, end_date: requestRangeModal.endDate});
        if (response.status === 200) {
            setState(prev => ({ ...prev, requestData: response.data }));
            showToast(response.message)
        } else {
            console.log('[EmployeeHour] createPaylocityRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const importData = async () => {
        const { fileData, filter } = state;

        alert('Be careful not to import the same file again unless an error has occurred');
        showLoader(true);
        const response = await post('/employeeHour/import', { ...filter, fileData });
        if (response.status === 200) {
            setState(prev => ({ ...prev, data: response.data, avoided: response.avoided }));
            if (response.avoided?.length > 0) {
                setOpen(true);
            }
            showToast(response.message)
        } else {
            console.log('[EmployeeHour] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const search = async () => {
        const { filter } = state;

        showLoader(true);
        const response = await get('/employeeHour/search', filter);
        if (response.status === 200) {
            setState(prev => ({ ...prev, data: response.data }));
            if (!response?.data[0]) showToast('There are no data with these filters', 'warning');
        } else {
            console.log('[EmployeeHour] search error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const destroy = async () => {
        const { startDate, endDate, employee } = state.filter;

        const cond = window.confirm(`This will delete data from ${startDate} to ${endDate} for ${employee.name}. \n\nThis action cannot be reversed. Do you want to continue?`);
        if (cond) {
            showLoader(true);
            const response = await del('/employeeHour', state.filter);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: [] }));
            } else {
                console.log('[EmployeeHour] desroy error', response);
                helpers.failRequest(response, showToast, signOut)
            }
            showLoader(false);
        }
    }

    const renderCell = ({ row }) => {
        console.log(row)
        if (row.status !== "Done"){
            return(
                <Tooltip title="Show data log" placement="top">
                    <IconButton disabled>
                        <Icon>{"timer"}</Icon>
                    </IconButton>
                </Tooltip>)
        }
        if (row.incomplete_data && row.status === "Done"){
            return (
                <Tooltip title="Show data log" placement="top">
                    <IconButton onClick={() => {setLogOpen(true); setAvoidedBotData(state.botAvoided.filter(i => {return i.guid === row.guid})); }}>
                        <Icon>{"warning"}</Icon>
                    </IconButton>
                </Tooltip>
            );
        }else{
            return (
                <Tooltip title="All data saved" placement="top">
                    <IconButton disabled>
                        <Icon>{"check_circle"}</Icon>
                    </IconButton>
                </Tooltip>
            );
        }
		
	};

    const getDate = params => dayjs(params.value).format('MM/DD/YYYY');
    const getTime = params => dayjs(params.value).format('h:mm A');
    const getName = params => params.value?.name ?? '?';

    const columns = [
        { field: 'date', headerName: 'Date', flex: 0.8, minWidth: 100, valueGetter: getDate },
        { field: 'employee', headerName: 'Employee', flex: 2, minWidth: 220, valueGetter: getName },
        { field: 'actionRef', headerName: 'Action', flex: 1, minWidth: 130, valueGetter: getName },
        { field: 'start', headerName: 'Start', flex: 1, minWidth: 220, valueGetter: getTime },
        { field: 'stop', headerName: 'Stop', flex: 1, minWidth: 220, valueGetter: getTime },
        { field: 'hours', headerName: 'Hours', flex: 0.8, minWidth: 130 },
        { field: 'cc2', headerName: 'CC2', flex: 0.8, minWidth: 100, valueGetter: getName },
        { field: 'cc3', headerName: 'CC3', flex: 0.8, minWidth: 100, valueGetter: getName }
    ];

    const requestColumns = [
        { field: 'idbot_request', headerName: 'Request ID', flex: 0.6, minWidth: 200 },
        { field: 'status', headerName: 'Status', flex: 0.6, minWidth: 130 },
        { field: 'start_date', headerName: 'Start Date', flex: 1, minWidth: 220, valueGetter: getDate },
        { field: 'end_date', headerName: 'End Date', flex: 1, minWidth: 220, valueGetter: getDate },
        { field: 'created_at', headerName: 'Created Date', flex: 1, minWidth: 220, valueGetter: getDate },
        { field: 'incomplete_data', headerName: "Logs", flex: 0.5, renderCell },
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Employee hour' />

            <Grid container>
                <Grid item sm={12} md={6} lg={6}>
                    <FormGroup>
                        <FormLabel>{'Upload file'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='file'
                                onChange={handleFile}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>

                <Grid item sm={12} md={6} lg={6}>
                    <FormGroup>
                        <FormLabel>{'Paylocity report date range'}</FormLabel>
                        <FormControl
                            onClick={handleRequestModal}
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                readOnly
                                type='text'
                                placeholder='Paylocity report date range'
                                value={state.requestRangeModal.dateRange}
                            />
                            
                        </FormControl>

                        <Button 
                            variant="contained"
                            onClick={createPaylocityRequest}
                            className={classes.acceptButton}>
                            Add Request
                        </Button>

                    </FormGroup>
                            
                    
                </Grid>
            </Grid>

            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs value={tabValue} onChange={handleTabChange} 
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary" 
                    aria-label="simple tabs example">
                    <Tab label="Employee Hours Data" {...a11yProps(0)} />
                    <Tab onClick={importPaylocityRequestData} label="Paylocity Data Requests" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={tabValue} index={0}>
                    <Grid container>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormGroup onClick={handleModal}>
                                <FormLabel>{'Date range'}</FormLabel>
                                <FormControl
                                    variant='filled'
                                    component={Box}
                                    width='100%'
                                >
                                    <FilledInput
                                        readOnly
                                        type='text'
                                        placeholder='Date range'
                                        value={state.filter.dateRange}
                                    />
                                </FormControl>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                            <FormGroup>
                                <FormLabel>{'Employee'}</FormLabel>
                                <Autocomplete
                                    options={state.employees}
                                    value={state.filter.employee}
                                    getOptionLabel={employee => employee?.name ?? ''}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    onChange={(event, value) => handleAutocomplete('employee', value)}
                                    renderInput={params => <TextField {...params} placeholder='Employee' />}
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>

                    <div className={classes.buttonsContainer}>
                        {
                            state.permissions.create_attr &&
                            <Button
                                variant='contained'
                                className={classes.import}
                                disabled={state.fileData.length < 1}
                                onClick={importData}
                            >
                                {'Import data'}
                            </Button>
                        }
                        {
                            state.permissions.delete_attr &&
                            <Tooltip title='This button will delete the data based on the fields above.' placement='top'>
                                <Button
                                    variant='contained'
                                    className={classes.delete}
                                    onClick={destroy}
                                >
                                    {'Delete'}
                                </Button>
                            </Tooltip>
                        }
                        <Button
                            variant='contained'
                            className={classes.search}
                            onClick={search}
                        >
                            {'Search'}
                        </Button>
                    </div>
                    <div className={classes.tableContainer}>
                        <DataGrid
                            rows={state.data}
                            columns={columns}
                            pageSize={45}
                            density='compact'
                            autoHeight
                            getRowId={row => row.idemployee_hour}
                        />
                    </div>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <div className={classes.tableContainer}>
                        <DataGrid
                            rows={state.requestData}
                            columns={requestColumns}
                            pageSize={45}
                            density='compact'
                            autoHeight
                            getRowId={row => row.idbot_request}
                        />
                    </div>
                </TabPanel>

            </div>
            

            <Avoided open={open} onClose={() => setOpen(false)} array={state.avoided} />

            <AvoidedBot open={logOpen} onClose={() => setLogOpen(false)} data={avoidedBotData[0] ?? {}} />

            <DateRange
                maxDate={new Date()}
                open={state.requestRangeModal.open}
                range={state.requestRangeModal.range}
                onClose={handleRequestModal}
                onChange={handleRequestDateChange}
            />
            <DateRange
                open={state.rangeModal.open}
                range={state.rangeModal.range}
                onClose={handleModal}
                onChange={handleDateChange}
            />
            
        </div>
    );
}

export default EmployeeHour;