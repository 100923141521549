import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, TextField, Tooltip, IconButton, Icon, MenuItem, Select } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation, Link } from 'react-router-dom';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%'
    }
}));

const Products = () => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [state, setState] = useState({
        loading: true,
        categories: [],
        product_types: [],
        products: [],
        product: {
            idproduct: 0,
            code: '',
            name: '',
            idcategory: 0,
            category: {},
            idproduct_type: 0,
            product_type: {},
            type_bom: 'raw',
            unit_cost: 0,
            unit_measure: ''
        },
        permissions: {}
    });

    useEffect(() => {
        window.document.title = 'Products';

        validatePermissions();
    }, []);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/products');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                products: response?.data ?? [],
                categories: response?.categories ?? [],
                product_types: response?.product_types ?? [],
                product: {
                    ...prev.product,
                    category: response?.categories[0] ?? {},
                    product_type: response?.product_types[0] ?? {}
                }
            }));
        } else {
            console.log('[Products] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, product: { ...prev.product, [target.name]: target.value } }));

    const validateForm = (type) => {
        const { idproduct, code, name, category, product_type, type_bom, unit_cost, unit_measure } = state.product;

        switch (type) {
            case 'update':
                const data = state.products.filter(value => value.idproduct === idproduct);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.product);
                    if (before !== after) {
                        if (code.trim() && name.trim() && category?.id && product_type?.id && type_bom && Number(unit_cost) && unit_measure.trim()) {
                            const cond = window.confirm(`If you update the unit cost field, then all affected data will be updated: \n- Recipes \n\nDo you want to continue with this update?`);

                            return cond;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some product', 'error');
                    return false;
                }

            case 'store':

                if (code.trim() && name.trim() && category?.id && product_type?.id && type_bom && Number(unit_cost) && unit_measure.trim()) {
                    return true;
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (idproduct === 0) {
                    showToast('Select some product', 'error');
                    return false;
                }
                const cond = window.confirm(`Are you sure you want to delete this product: ${code}?`);

                return Boolean(idproduct && cond);
            default:
                break;
        }
    }

    const clear = () => setState(prev => ({
        ...prev,
        product: {
            ...prev.product,
            idproduct: 0,
            code: '',
            name: '',
            category: prev.categories[0],
            product_type: prev.product_types[0],
            type_bom: 'raw',
            unit_cost: 0,
            unit_measure: ''
        }
    }));


    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/product/store', state.product);
            if (response.status === 200) {
                setState(prev => ({ ...prev, products: response?.data ?? prev.products }));
                showToast(response.message);
            } else {
                console.log('[Products] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idproduct } = state.product;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/product/${idproduct}/update`, state.product);
            if (response.status === 200) {
                setState(prev => ({ ...prev, products: response?.data ?? prev.products }));
                showToast(response.message);
            } else {
                console.log('[Products] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idproduct } = state.product;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/product/${idproduct}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, products: response?.data ?? prev.products }));
                showToast(response.message);
            } else {
                console.log('[Products] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const valueGetter = params => params.value?.name ?? 'N/S';

    const getUnitCost = params => params.row?.sedes[0]?.[params.field] ?? 'N/S';

    const getTypeBOM = ({ value }) => {
        switch (value) {
            case 'raw':
                return 'Raw';

            case 'material':
                return 'Material'

            case 'no_bom':
                return 'No BOM'

            default:
                return 'N/A';
        }
    }

    const renderCell = ({ row }) => {
        return (
            <Link to={`/history/products/${row.idproduct}`} target='_blank'>
                <Tooltip title='Show history' placement='top'>
                    <IconButton>
                        <Icon>{'history'}</Icon>
                    </IconButton>
                </Tooltip>
            </Link>
        );
    }

    const columns = [
        { field: 'code', headerName: 'Code', flex: 1, minWidth: 120 },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 150 },
        { field: 'category', headerName: 'Category', flex: 1, minWidth: 150, valueGetter },
        { field: 'product_type', headerName: 'Type', flex: 1, minWidth: 150, valueGetter },
        { field: 'type_bom', headerName: 'Type (BOM)', flex: 1, minWidth: 150, valueGetter: getTypeBOM },
        { field: 'unit_cost', headerName: 'Unit cost', flex: 1, minWidth: 150, valueGetter: getUnitCost },
        { field: 'unit_measure', headerName: 'Unit measure', flex: 1, minWidth: 150 },
        { field: 'actions', headerName: 'Actions', flex: 0.5, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }
    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='Products' />
            <Grid container>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Code'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Code'
                                name='code'
                                value={state.product.code}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Name'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Name'
                                name='name'
                                value={state.product.name}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Category'}</FormLabel>

                        <Autocomplete
                            options={state.categories}
                            value={state.product.category}
                            getOptionLabel={category => category?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, category) => {
                                setState(prev => ({ ...prev, product: { ...prev.product, category } }));
                            }}
                            renderInput={(params) => <TextField {...params} placeholder='Category' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Product type'}</FormLabel>

                        <Autocomplete
                            options={state.product_types}
                            value={state.product.product_type}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, product_type) => {
                                setState(prev => ({ ...prev, product: { ...prev.product, product_type } }));
                            }}
                            renderInput={(params) => <TextField {...params} placeholder='Product type' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Type (BOM)'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <Select
                                value={state.product.type_bom}
                                onChange={({ target }) => setState(prev => ({ ...prev, product: { ...prev.product, type_bom: target.value } }))}
                            >
                                <MenuItem value='raw'>{'Raw'}</MenuItem>
                                <MenuItem value='material'>{'Material'}</MenuItem>
                                <MenuItem value='no_bom'>{'No BOM'}</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Unit cost'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                placeholder='Unit cost'
                                name='unit_cost'
                                value={state.product.unit_cost}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <FormGroup>
                        <FormLabel>{'Unit measure'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Unit measure'
                                name='unit_measure'
                                value={state.product.unit_measure}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            {
                state.permissions.delete_attr &&
                <Trash table='products' id='idproduct' onRefresh={makeRequest} />
            }

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.products}
                    columns={columns}
                    pageSize={45}
                    density='compact'
                    autoHeight
                    getRowId={row => row.idproduct}
                    onRowClick={({ row }) => {
                        const unit_cost = row?.sedes[0]?.unit_cost ?? 0;
                        const product_type = row?.product_type ?? state.product_types[0];
                        setState(prev => ({ ...prev, product: { ...row, unit_cost, product_type } }));
                    }}
                />
            </div>
        </div>
    );
}

export default Products;