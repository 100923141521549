import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, Typography, Button, MenuItem } from '@material-ui/core';

import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '80%',
        height: '50%',
        borderRadius: 10,
    },
    bottom: {
        display: 'flex',
        alignSelf: 'flex-end'
    },
    body: {
        overflow: 'auto',
        flex: 1,
        marginTop: 15
    }
}));

const CreateError = ({ open, onClose, array }) => {

    const classes = useStyles();

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.root}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Employees codes'}</Typography>
                <Typography variant='subtitle1'>{'The data below does not have a assigned hours.'}</Typography>

                <div className={classes.body}>
                    {
                        array?.map((item, i) => <MenuItem key={`${i}`}>{item}</MenuItem>)
                    }
                </div>

                <div className={classes.bottom}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={onClose}
                    >
                        {'Close'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default CreateError;