import React, { useState, useContext, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Modal, FilledInput, Typography, Button, FormGroup, FormLabel, FormControl, Box, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import WeekPicker from '../../common/week-picker';

import themeColors from '../../assets/colors';
import { get, post } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import AuthContext from '../../context';
import CreateError from './create-error';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: themeColors.background.default,
        padding: 20,
        width: '70%',
        borderRadius: 10
    },
    store: {
        marginLeft: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    bottom: {
        display: 'flex',
        alignSelf: 'flex-end',
    }
}));

const CreateEmployeePay = ({ open, onClose }) => {

    const classes = useStyles();
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const history = useHistory();
    const isMounted = useRef(false);
    const [showWeekPicker, setShowWeekPicker] = useState(false);
    const [showError, setShowError] = useState(false);
    const [state, setState] = useState({
        employees: [],
        header: {
            week: '',
            start: '',
            end: '',
            employee: { id: 0, name: 'All employees' }
        },
        rejected: []
    });

    useEffect(() => {
        if (open && !isMounted.current) {
            makeRequest();
            isMounted.current = true;
        }
    }, [open]);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/employeePay/create');
        if (response.status === 200) {
            setState(prev => ({ ...prev, employees: response?.employees ?? [] }));
        } else {
            console.log('[CreateEmployeePay] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, header: { ...prev.header, [target.name]: target.value } }));

    const validateForm = () => {
        const { week } = state.header;

        if (week) {
            store();
        } else {
            showToast('You must select a week', 'error');
        }
    }

    const store = async () => {
        showLoader(true);
        const response = await post('/employeePay/store', state.header);
        if (response.status === 200) {
            history.push(`/production/employeePay/index`);
            showToast(response.message);
        } else {
            console.log('[CreateEmployeePays] store error', response);
            helpers.failRequest(response, showToast, signOut);

            setState(prev => ({ ...prev, rejected: response?.rejected ?? [] }));
            if (response?.rejected?.length > 0) {
                setShowError(true);
            }
        }
        showLoader(false);
    }

    const setWeek = ({ week, start, end }) => {
        setShowWeekPicker(false);
        setState(prev => ({ ...prev, header: { ...prev.header, week, start, end } }));
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            className={classes.root}
        >
            <div className={classes.container}>
                <Typography variant='h2'>{'Create payments'}</Typography>
                <Grid container>
                    <Grid item xs={12} md={6} lg={6}>
                        <FormGroup>
                            <FormLabel>{'Week'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                                onClick={() => setShowWeekPicker(true)}
                            >
                                <FilledInput
                                    readOnly
                                    type='text'
                                    placeholder='Select a week'
                                    name='week'
                                    value={state.header.week}
                                    onChange={handleChange}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        <FormGroup>
                            <FormLabel>{'Employee'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    disabled
                                    autoComplete='off'
                                    type='text'
                                    placeholder='All employees'
                                    name='week'
                                    value={'All employees'}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
                <div className={classes.bottom}>
                    <Button
                        variant='contained'
                        color='secondary'
                        onClick={onClose}
                    >
                        {'Cancel'}
                    </Button>
                    <Button
                        variant='contained'
                        className={classes.store}
                        onClick={validateForm}
                    >
                        {'Store'}
                    </Button>
                </div>

                <WeekPicker
                    open={showWeekPicker}
                    onClose={() => setShowWeekPicker(false)}
                    setWeek={setWeek}
                    dayAvailable={'1'}
                />

                <CreateError open={showError} onClose={() => setShowError(false)} array={state.rejected} />
            </div>
        </Modal>
    );
}

export default CreateEmployeePay;