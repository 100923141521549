import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, TextField, Button, Tooltip, IconButton, Icon } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation, Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import Loader from '../../common/loader';
import Crud from '../../common/crud';
import NotFound from '../../common/not-found';
import ViewTitle from '../../common/view-title';
import Trash from '../../common/trash';

import AuthContext from '../../context';
import { del, get, post, put } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import ProductSaleBarcodeToPrint from '../components/product-sale-barcode-to-print';
import UploadImage from '../components/upload-image';
import ProductSalesToDownload from '../components/product-sales-to-download';
import ProductSaleToPrint from '../components/product-sale-to-print';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        paddingBottom: 20
    },
    tableContainer: {
        width: '100%',
        '& .bold': {
            fontWeight: 'bold'
        },
        '& .deactivated': {
            backgroundColor: '#FBEFEF'
        },
        '& .ss': {
            backgroundColor: '#EFF2FB'
        },
    },
    productSalesToPrint: {
        display: 'none'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    print: {
        marginRight: 15,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none'
        }
    },
    imgContainer: {
        height: 100
    },
    img: {
        cursor: 'pointer',
        width: 100,
        height: 100,
        objectFit: 'contain'
    }
}));

const ProductSales = () => {

    const imgRef = useRef(null);
    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const { pathname } = useLocation();
    const [uploadModal, setUploadModal] = useState({ open: false, item: {} });
    const [selectedProductSales, setSelectedProductSales] = useState([]);
    const [state, setState] = useState({
        loading: true,
        departments: [],
        productSales: [],
        productSale: {
            idproduct_sale: 0,
            code: '',
            description: '',
            pack_qty: 1,
            average_weight: 0,
            iddepartment: 0,
            department: {},
            image: ''
        },
        productSalesToPrint: [],
        dataToDownload: {},
        dataToPrint: [],
        permissions: {}
    });

    const printBarcodeRef = useRef(null);
    const printRecipeRef = useRef(null);

    const handlePrintBarcode = useReactToPrint({ content: () => printBarcodeRef.current });
    const handlePrintRecipe = useReactToPrint({ content: () => printRecipeRef.current });

    useEffect(() => {
        window.document.title = 'BOM cost';

        validatePermissions();
    }, []);

    useEffect(() => {
        if (state.productSalesToPrint.length > 0) {
            handlePrintBarcode();
        }
    }, [state.productSalesToPrint]);

    useEffect(() => {
        if (state.dataToPrint.length > 0) {
            handlePrintRecipe();
        }
    }, [state.dataToPrint]);

    const validatePermissions = () => {
        const permissions = helpers.getPermissions(pathname);
        setState(prev => ({ ...prev, permissions }));

        (permissions.read_attr) ? makeRequest() : setState(prev => ({ ...prev, loading: false }));
    }

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/productSales');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                productSales: response?.data ?? [],
                departments: response?.departments ?? [],
                productSale: {
                    ...prev.productSale,
                    department: response?.departments[0] ?? {},
                }
            }));
        } else {
            console.log('[ProductSales] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut)
        }
        showLoader(false);
    }

    const handleChange = ({ target }) => setState(prev => ({ ...prev, productSale: { ...prev.productSale, [target.name]: target.value } }));

    const handleChangeImage = ({ target }) => {
        const file = target?.files[0] ?? '';
        if (file) {

            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                setState(prev => ({ ...prev, productSale: { ...prev.productSale, image: reader.result } }));
            }
        } else {
            setState(prev => ({ ...prev, productSale: { ...prev.productSale, image: '' } }));
        }
    }

    const validateForm = (type) => {
        const { idproduct_sale, code, description, pack_qty, department, image } = state.productSale;

        switch (type) {
            case 'update':
                const data = state.productSales.filter(value => value.idproduct_sale === idproduct_sale);

                if (data[0]) {
                    const before = JSON.stringify(data[0]);
                    const after = JSON.stringify(state.productSale);
                    if (before !== after) {
                        if (code.trim() && description.trim() && department.id && Number(pack_qty)) {
                            return true;
                        } else {
                            showToast('All fields is required', 'error');
                            return false;
                        }
                    } else {
                        showToast('Update some field', 'error');
                        return false;
                    }
                } else {
                    showToast('Select some product sale', 'error');
                    return false;
                }

            case 'store':

                if (code.trim() && description.trim() && department.id && Number(pack_qty)) {
                    if (Boolean(image)) {
                        return true;
                    } else {
                        showToast('Image is required', 'error');
                        return false;
                    }
                } else {
                    showToast('All fields is required', 'error');
                    return false;
                }

            case 'delete':
                if (idproduct_sale === 0) {
                    showToast('Select some product sale', 'error');
                    return false;
                }
                const cond = window.confirm(`Are you sure you want to delete this product sale?`);

                return Boolean(idproduct_sale && cond);
            default:
                break;
        }
    }

    const clear = () => setState(prev => {
        if (imgRef.current) {
            imgRef.current.value = '';
        }

        return {
            ...prev,
            productSale: {
                ...prev.productSale,
                idproduct_sale: 0,
                code: '',
                description: '',
                pack_qty: 1,
                average_weight: 0,
                department: prev.departments[0],
                image: ''
            }
        }
    });


    const store = async () => {
        const cond = validateForm('store');

        if (cond) {
            showLoader(true);
            const response = await post('/productSale/store', state.productSale);
            if (response.status === 200) {
                setState(prev => ({ ...prev, productSales: response?.data ?? prev.productSales }));
                showToast(response.message);
            } else {
                console.log('[ProductSales] store error', response);
                helpers.failRequest(response, showToast, signOut);
                showLoader(false);

                return false;
            }
            showLoader(false);
        }

        return cond;
    }

    const update = async () => {
        const { idproduct_sale } = state.productSale;
        const cond = validateForm('update');

        if (cond) {
            showLoader(true);
            const response = await put(`/productSale/${idproduct_sale}/update`, state.productSale);
            if (response.status === 200) {
                setState(prev => ({ ...prev, productSales: response?.data ?? prev.productSales }));
                showToast(response.message);
            } else {
                console.log('[ProductSales] update error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const destroy = async () => {
        const { idproduct_sale } = state.productSale;
        const cond = validateForm('delete');

        if (cond) {
            showLoader(true);
            const response = await del(`/productSale/${idproduct_sale}`);
            if (response.status === 200) {
                setState(prev => ({ ...prev, productSales: response?.data ?? prev.productSales }));
                showToast(response.message);
            } else {
                console.log('[ProductSales] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const getProductSalesToPrint = async () => {
        const ids = selectedProductSales;

        if (ids.length < 1) {
            showToast('Select some product sale', 'error');
            return null;
        }

        let form = { idproduct_sale: ids.join('-') };

        showLoader(true);
        const response = await get('/productSales/printBarcode', form);
        if (response.status === 200) {
            setState(prev => ({ ...prev, productSalesToPrint: response?.data ?? [] }));

            if (response.data?.length === 0) showToast('No product sale to print', 'warning');
        } else {
            console.log('[ProductSales] getProductSalesToPrint error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const onCloseModal = (image = null) => {
        const data = state.productSales.slice();

        let id = 0;
        setUploadModal(prev => {
            id = prev.item.idproduct_sale;

            return ({ open: false, item: {} });
        });

        data.map(item => {
            if (item.idproduct_sale === id) {
                item.image = image;
            }

            return item;
        });

        setState(prev => ({ ...prev, productSales: data }));
    }

    const getDataToDownload = async () => {
        const ids = selectedProductSales;

        if (ids.length < 1) {
            showToast('Select some product sale', 'error');
            return null;
        }

        let form = { idproduct_sale: ids.join('-') };

        showLoader(true);
        const response = await get('/productSales/download', form);
        if (response.status === 200) {
            setState(prev => ({ ...prev, dataToDownload: response.data }));

            if (response.data?.recipes?.length === 0) showToast('No product sale to download', 'warning');
        } else {
            console.log('[ProductSales] getDataToDownload error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const getDataToPrint = async () => {
        const ids = selectedProductSales;

        if (ids.length < 1) {
            showToast('Select some product sale', 'error');
            return null;
        }

        let form = { idproduct_sale: ids.join('-') };

        showLoader(true);
        const response = await get('/productSales/print', form);
        if (response.status === 200) {
            setState(prev => ({ ...prev, dataToPrint: response.data }));

            if (response.data?.length === 0) showToast('No product sale to print', 'warning');
        } else {
            console.log('[ProductSales] getDataToPrint error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const valueGetter = params => params.value.name;

    const getProductSaleSede = ({ row, field }) => row?.sedes[0]?.[field] ?? 'N/A';

    const renderCell = ({ row }) => {
        return (
            <div>
                <Link to={`/bom/recipe/detail?id=${row.idproduct_sale}`} target='_blank'>
                    <Tooltip title='Show detail' placement='top'>
                        <IconButton>
                            <Icon>{'visibility'}</Icon>
                        </IconButton>
                    </Tooltip>
                </Link>
                <Tooltip title='Upload image' placement='top'>
                    <IconButton onClick={() => setUploadModal({ open: true, item: row })}>
                        <Icon>{'add_photo_alternate'}</Icon>
                    </IconButton>
                </Tooltip>
                <Link to={`/history/product_sales/${row.idproduct_sale}`} target='_blank'>
                    <Tooltip title='Show history' placement='top'>
                        <IconButton>
                            <Icon>{'history'}</Icon>
                        </IconButton>
                    </Tooltip>
                </Link>
            </div>
        );
    }

    const renderImage = ({ value }) => {
        const url = `https://cfg-images-bucket.s3.amazonaws.com/recipes/${value}`;
        return (
            <div className={classes.imgContainer}>
                {
                    value ?
                        <a href={url} target='_blank' rel='noreferrer'><img src={url} className={classes.img} alt='product' /></a>
                        :
                        <span>{'N/A'}</span>
                }
            </div>
        );
    }

    const columns = [
        { field: 'code', headerName: 'Code', flex: 0.6, minWidth: 90 },
        { field: 'description', headerName: 'Description', flex: 1.7, minWidth: 180 },
        { field: 'department', headerName: 'Department', flex: 1, minWidth: 110, valueGetter },
        { field: 'final_cost_product', headerName: 'Final cost per product', flex: 1, minWidth: 90, valueGetter: getProductSaleSede },
        { field: 'final_cost_pack', headerName: 'Final cost per pack', flex: 1, minWidth: 90, valueGetter: getProductSaleSede },
        { field: 'pack_qty', headerName: 'Pack qty', flex: 0.7, minWidth: 90 },
        { field: 'piece_rate', headerName: 'Piece rate', flex: 0.7, minWidth: 90, valueGetter: getProductSaleSede },
        { field: 'image', headerName: 'Image', flex: 0.7, minWidth: 100, renderCell: renderImage },
        { field: 'actions', headerName: 'Actions', flex: 0.7, minWidth: 100, renderCell }
    ];

    if (state.loading) {
        return <Loader />;
    }

    if (!state.permissions.read_attr) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <ViewTitle title='BOM cost' />
            <Grid container>
                <Grid item xs={12} md={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Code'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Code'
                                name='code'
                                value={state.productSale.code}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <FormGroup>
                        <FormLabel>{'Description'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='text'
                                placeholder='Description'
                                name='description'
                                value={state.productSale.description}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Department'}</FormLabel>

                        <Autocomplete
                            options={state.departments}
                            value={state.productSale.department}
                            getOptionLabel={department => department?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, department) => {
                                setState(prev => ({ ...prev, productSale: { ...prev.productSale, department } }));
                            }}
                            renderInput={(params) => <TextField {...params} placeholder='Department' />}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Pack quantity'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                placeholder='Pack quantity'
                                name='pack_qty'
                                value={state.productSale.pack_qty}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Average weight'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                autoComplete='off'
                                type='number'
                                placeholder='Average weight'
                                name='average_weight'
                                value={state.productSale.average_weight}
                                onChange={handleChange}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                {
                    !Boolean(state.productSale.idproduct_sale) &&
                    <Grid item xs={12} md={6} lg={3}>
                        <FormGroup>
                            <FormLabel>{'Image'}</FormLabel>
                            <FormControl
                                variant='filled'
                                component={Box}
                                width='100%'
                            >
                                <FilledInput
                                    type='file'
                                    inputProps={{ accept: 'image/*' }}
                                    placeholder='Image'
                                    inputRef={imgRef}
                                    onChange={handleChangeImage}
                                />
                            </FormControl>
                        </FormGroup>
                    </Grid>
                }
            </Grid>

            <Crud
                permissions={state.permissions}
                create={{ create: clear, store }}
                update={update}
                destroy={destroy}
                cancel={clear}
            />

            <div className={classes.buttonContainer}>
                <div>
                    <Button
                        variant='contained'
                        className={classes.print}
                        onClick={getDataToPrint}
                    >
                        {'Print recipes'}
                    </Button>
                </div>

                <ProductSalesToDownload data={state.dataToDownload} onClick={getDataToDownload} onReset={() => setState(prev => ({ ...prev, dataToDownload: {} }))} />

                <div>
                    <Button
                        variant='contained'
                        className={classes.print}
                        onClick={getProductSalesToPrint}
                    >
                        {'Print barcodes'}
                    </Button>
                </div>
                {
                    state.permissions.delete_attr &&
                    <Trash table='product_sales' id='idproduct_sale' onRefresh={makeRequest} />
                }
            </div>

            <div className={classes.tableContainer}>
                <DataGrid
                    rows={state.productSales}
                    columns={columns}
                    pageSize={45}
                    autoHeight
                    rowHeight={100}
                    getRowId={row => row.idproduct_sale}
                    onRowClick={({ row }) => setState(prev => ({ ...prev, productSale: { ...row } }))}
                    checkboxSelection
                    disableSelectionOnClick
                    onSelectionModelChange={array => {
                        setSelectedProductSales(array);
                        setState(prev => ({ ...prev, dataToDownload: {} }));
                    }}
                    getCellClassName={({ field }) => (['final_cost_product', 'final_cost_pack'].includes(field)) ? 'bold' : ''}
                    getRowClassName={({ row }) => (row?.supplier_shed === true) ? 'ss' : (row?.sedes[0]?.active === false) ? 'deactivated' : ''}
                />
            </div>

            <UploadImage open={uploadModal.open} onClose={onCloseModal} item={uploadModal.item} />

            <div className={classes.productSalesToPrint}>
                <div ref={printBarcodeRef}>
                    <ProductSaleBarcodeToPrint data={state.productSalesToPrint} />
                </div>
            </div>

            <div className={classes.productSalesToPrint} >
                <div ref={printRecipeRef}>
                    <ProductSaleToPrint data={state.dataToPrint} />
                </div>
            </div>
        </div>
    );
}

export default ProductSales;