import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, FormLabel, FormGroup, FormControl, FilledInput, TextField, Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useReactToPrint } from 'react-to-print';
import isoWeek from 'dayjs/plugin/isoWeek';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import WeekPicker from '../../common/week-picker';
import EmployeePayToPrint from '../components/employee-pay-to-print';
import EmployeePaySummaryToPrint from '../components/employee-pay-summary-to-print';

import AuthContext from '../../context';
import helpers from '../../utils/helpers';
import { del, get } from '../../utils/api-services';
import themeColors from '../../assets/colors';

dayjs.extend(isoWeek);

const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: 20
    },
    search: {
        marginLeft: 20,
        backgroundColor: '#0d6efd',
        borderColor: '#0d6efd',
        '&:hover': {
            backgroundColor: '#0d6efd',
            borderColor: '#0d6efd',
            boxShadow: 'none',
        }
    },
    print: {
        marginLeft: 20,
        backgroundColor: '#6E6E6E',
        borderColor: '#6E6E6E',
        '&:hover': {
            backgroundColor: '#6E6E6E',
            borderColor: '#6E6E6E',
            boxShadow: 'none'
        }
    },
    delete: {
        marginLeft: 20,
        backgroundColor: '#dc3545',
        borderColor: '#dc3545',
        '&:hover': {
            backgroundColor: '#dc3545',
            borderColor: '#dc3545',
            boxShadow: 'none'
        }
    },
    tableContainer: {
        width: '100%',
        '& .row-error': {
            backgroundColor: themeColors.visible['false']
        },
    },
    dataToPrint: {
        display: 'none'
    }
}));

const EmployeePayIndex = ({ permissions }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const classes = useStyles();
    const [showPicker, setShowPicker] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [state, setState] = useState({
        loading: true,
        employees: [],
        filter: {
            week: `${dayjs().isoWeekYear()}-${dayjs().isoWeek()}`,
            employee: { id: 0, name: 'All employees' }
        },
        data: [],
        dataToPrint: [],
        permissions: {}
    });

    const printRef = useRef(null);
    const printRef2 = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });
    const handlePrint2 = useReactToPrint({
        content: () => printRef2.current
    });


    useEffect(() => {
        window.document.title = 'Employee pay';

        setState(prev => ({ ...prev, permissions }));
    }, [permissions]);

    useEffect(() => {
        if (state.dataToPrint.length > 0) {
            handlePrint();
        }
    }, [state.dataToPrint]);

    useEffect(() => {
        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get('/employeePays');
        if (response.status === 200) {
            setState(prev => ({
                ...prev,
                loading: false,
                employees: response?.employees ?? [],
                data: response?.data ?? []
            }));
        } else {
            console.log('[EmployeePayIndex] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const handleChangeAutocomplete = (key, value) => setState(prev => ({ ...prev, filter: { ...prev.filter, [key]: value } }));

    const setWeek = ({ week }) => {
        setShowPicker(false);
        setState(prev => ({ ...prev, filter: { ...prev.filter, week } }));
    }

    const search = async () => {
        const { week } = state.filter;
        if (week) {
            showLoader(true);
            const response = await get('/employeePay/search', state.filter);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? [] }));

                if (!response?.data[0]) showToast('There are no checks with these filters', 'warning');
            } else {
                console.log('[EmployeePayIndex] search error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        } else {
            showToast('You must select a week', 'error');
        }
    }

    const getDataToPrint = async () => {
        const ids = selectedData;

        if (ids.length < 1) {
            showToast('Select some data', 'error');
            return null;
        }

        const form = { idemployee_pay_header: ids.join('-') };

        showLoader(true);
        const response = await get('/employeePay/print', form);
        if (response.status === 200) {
            setState(prev => ({ ...prev, dataToPrint: response?.data ?? [] }));

            if (response.data?.length === 0) showToast('There is no data to print', 'warning');
        } else {
            console.log('[EmployeePayIndex] getDataToPrint error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const destroy = async () => {
        const ids = selectedData;

        if (ids.length < 1) {
            showToast('Select some data', 'error');
            return null;
        }

        const cond = window.confirm(`Are you sure you want to delete ${ids.length} payments? \n\nThis action cannot be reversed.`);
        if (cond) {
            const form = { idemployee_pay_header: ids.join('-'), ...state.filter };

            showLoader(true);
            const response = await del('/employeePay/multipleDelete', form);
            if (response.status === 200) {
                setState(prev => ({ ...prev, data: response?.data ?? [] }));

                showToast(response.message);
            } else {
                console.log('[EmployeePayIndex] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const printSummary = async () => {
        const data = state.data;

        if (data.length) {
            handlePrint2();
        } else {
            showToast('There is no data to print', 'warning');
        }
    }

    const valueGetter = params => params.value.name;
    const renderTotal = params => `$ ${params.value}`;
    const renderCell = ({ row }) => {
        return (
            <>
                <Link to={`/production/employeePay/${row.idemployee_pay_header}`} target='_blank'>
                    <Tooltip title='Show detail' placement='top'>
                        <IconButton>
                            <Icon>{'visibility'}</Icon>
                        </IconButton>
                    </Tooltip>
                </Link>
                {
                    row.error &&
                    <Tooltip title='Bonus > 0 & Regular hours (M) = 0' placement='top'>
                        <IconButton>
                            <Icon>{'error'}</Icon>
                        </IconButton>
                    </Tooltip>
                }
            </>
        );
    }

    const columns = [
        { field: 'week', headerName: 'Week', flex: 0.5, minWidth: 120 },
        { field: 'employee', headerName: 'Employee', flex: 1, minWidth: 240, valueGetter },
        { field: 'total', headerName: 'Total', flex: 0.7, minWidth: 160, valueGetter: renderTotal },
        { field: 'bonus', headerName: 'Bonus', flex: 0.7, minWidth: 160, valueGetter: renderTotal },
        { field: 'overtime_base_rate', headerName: 'OT base rate', flex: 0.7, minWidth: 160, valueGetter: renderTotal },
        { field: 'hours', headerName: 'Hours', flex: 0.7, minWidth: 160 },
        { field: 'detail', headerName: 'Actions', flex: 0.5, minWidth: 120, renderCell }
    ]

    if (state.loading) {
        return <Loader />;
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} lg={6}>
                    <FormGroup onClick={() => setShowPicker(true)}>
                        <FormLabel>{'Week'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                readOnly
                                type='text'
                                placeholder='Select a week'
                                value={state.filter.week}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormGroup>
                        <FormLabel>{'Employee'}</FormLabel>
                        <Autocomplete
                            options={state.employees}
                            value={state.filter.employee}
                            getOptionLabel={option => option?.name ?? ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={(event, value) => handleChangeAutocomplete('employee', value)}
                            renderInput={params => <TextField {...params} placeholder='Employee' />}
                        />
                    </FormGroup>
                </Grid>
            </Grid>

            <div className={classes.buttonsContainer}>
                <Button
                    variant='contained'
                    className={classes.search}
                    onClick={search}
                >
                    {'Search'}
                </Button>
                <Button
                    variant='contained'
                    className={classes.print}
                    onClick={getDataToPrint}
                >
                    {'Print selected'}
                </Button>
                {
                    state.permissions.delete_attr &&
                    <Button
                        variant='contained'
                        className={classes.delete}
                        onClick={destroy}
                    >
                        {'Delete'}
                    </Button>
                }
                <Button
                    variant='contained'
                    className={classes.print}
                    onClick={printSummary}
                >
                    {'Print summary'}
                </Button>
            </div>

            <div className={classes.tableContainer}>
                <DataGrid
                    autoHeight
                    pageSize={45}
                    density='compact'
                    columns={columns}
                    rows={state.data}
                    getRowId={row => row.idemployee_pay_header}
                    disableSelectionOnClick
                    checkboxSelection
                    onSelectionModelChange={array => setSelectedData(array)}
                    getRowClassName={({ row }) => (row.error) ? 'row-error' : ''}
                />
            </div>

            <WeekPicker
                open={showPicker}
                onClose={() => setShowPicker(false)}
                setWeek={setWeek}
                dayAvailable={'1'}
            />

            <div className={classes.dataToPrint}>
                <div ref={printRef}>
                    <EmployeePayToPrint data={state.dataToPrint} />
                </div>
            </div>

            <div className={classes.dataToPrint}>
                <div ref={printRef2}>
                    <EmployeePaySummaryToPrint week={state.filter.week} data={state.data} />
                </div>
            </div>
        </div>
    );
}

export default EmployeePayIndex;