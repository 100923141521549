import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Box, FormControl, FormLabel, FormGroup, FilledInput, Divider, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import dayjs from 'dayjs';

import Loader from '../../common/loader';
import NotFound from '../../common/not-found';

import AuthContext from '../../context';
import { get, del } from '../../utils/api-services';
import helpers from '../../utils/helpers';
import themeColors from '../../assets/colors';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    delete: {
        width: '100%',
        backgroundColor: '#dc3545',
        borderColor: '#dc3545',
        '&:hover': {
            backgroundColor: '#dc3545',
            borderColor: '#dc3545',
            boxShadow: 'none',
        },
    },
    divider: {
        marginBottom: 24
    },
    assignContainer: {
        marginTop: 24
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    rowText: {
        display: 'inherit',
        flexDirection: 'inherit',
        alignItems: 'inherit'
    },
    text: {
        fontSize: '1rem',
        marginLeft: 5,
        marginRight: 10,
        color: themeColors.gray[600]
    },
    detail: {
        backgroundColor: themeColors.white.main
    },
    textContainer: {
        fontSize: 10,
        color: '#000',
        textOverflow: 'ellipsis'
    },
    tableHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: 8,
        backgroundColor: '#F2F2F2'
    },
    totalTable: {
        backgroundColor: themeColors.app.main
    },
    total: {
        backgroundColor: '#F2F2F2'
    },
    footer: {
        backgroundColor: '#6E6E6E',
        color: '#fff'
    },
    totalContainer: {
        fontSize: 10,
        color: '#fff'
    }
}));

const Text = ({ title, color }) => {
    const classes = useStyles();

    return <TableCell
        align='center'
        style={{ backgroundColor: color ? color : 'transparent' }}
        className={classes.textContainer}
    >
        {title}
    </TableCell>
}

const Total = ({ title, result }) => {
    const classes = useStyles();

    return <TableCell
        align='center'
        style={{ fontSize: result ? 13 : 10 }}
        className={classes.totalContainer}>
        {title}
    </TableCell>
}

const EmployeePayDetail = ({ permissions }) => {

    const { showToast, signOut, showLoader } = useContext(AuthContext);
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [state, setState] = useState({
        loading: true,
        header: {},
        subheadersM: [],
        subheadersNM: [],
        permissions: {}
    });

    useEffect(() => {
        setState(prev => ({ ...prev, permissions }));
    }, [permissions]);

    useEffect(() => {
        window.document.title = 'Payment detail';

        makeRequest();
    }, []);

    const makeRequest = async () => {
        showLoader(true);
        const response = await get(`/employeePay/${id}`);
        if (response.status === 200) {
            const sede = JSON.parse(localStorage.getItem('@sede'));
            if (response.header?.idsede !== sede.id) {
                showToast('This Payment is not available', 'error');
                history.replace('/production/employeePay/index');
            } else {
                setState(prev => ({
                    ...prev,
                    loading: false,
                    header: response?.header ?? {},
                    subheadersM: response?.subheadersM ?? [],
                    subheadersNM: response?.subheadersNM ?? []
                }));
            }
        } else {
            console.log('[EmployeePayDetail] makeRequest error', response);
            helpers.failRequest(response, showToast, signOut);
        }
        showLoader(false);
    }

    const destroy = async () => {
        const cond = window.confirm('This action cannot be reversed. Do you want to continue?');

        if (cond) {
            showLoader(true);
            const response = await del(`/employeePay/${id}`);
            if (response.status === 200) {
                showToast(response.message, 'error');
                history.replace('/production/employeePay/index');
            } else {
                console.log('[EmployeePayDetail] destroy error', response);
                helpers.failRequest(response, showToast, signOut);
            }
            showLoader(false);
        }
    }

    const getTotalM = subheadersM => {
        let hours = '0', regular_hours = '0', regular_hours_pay = '0', overtime_hours = '0', overtime_hours_pay = '0', total_qty = '0', base_pay = '0', real_pay = '0', bonus = '0', subsidy = '0';

        subheadersM?.map(subheader => {
            hours = (Number(hours) + Number(subheader.hours)).toFixed(2);
            regular_hours = (Number(regular_hours) + Number(subheader.regular_hours)).toFixed(2);
            regular_hours_pay = (Number(regular_hours_pay) + Number(subheader.regular_hours_pay)).toFixed(2);
            overtime_hours = (Number(overtime_hours) + Number(subheader.overtime_hours)).toFixed(2);
            overtime_hours_pay = (Number(overtime_hours_pay) + Number(subheader.overtime_hours_pay)).toFixed(2);
            base_pay = (Number(base_pay) + Number(subheader.base_pay)).toFixed(2);
            bonus = (Number(bonus) + Number(subheader.bonus)).toFixed(2);
            subsidy = (Number(subsidy) + Number(subheader.subsidy)).toFixed(2);
            real_pay = (Number(real_pay) + Number(subheader.real_pay)).toFixed(2);

            subheader.detail?.map(item => {
                total_qty = Number(total_qty) + Number(item.total_qty);

                return false;
            });

            return false;
        });

        return [hours, regular_hours, `$ ${regular_hours_pay}`, overtime_hours, `$ ${overtime_hours_pay}`, total_qty, `$${base_pay}`, `$${bonus}`, `$${subsidy}`, `$${real_pay}`];
    }

    const getTotalsNM = subheadersNM => {
        let hours = 0, reg = 0, regPay = 0, ot1 = 0, ot1Pay = 0, ot2 = 0, ot2Pay = 0, total = 0;

        subheadersNM?.map(subheader => {
            subheader?.detail?.map(item => {
                hours = (Number(hours) + Number(item.hours)).toFixed(2);
                reg = (Number(reg) + Number(item.regular_hours)).toFixed(2);
                regPay = (Number(regPay) + Number(item.regular_hours_pay)).toFixed(2);
                ot1 = (Number(ot1) + Number(item.overtime_week)).toFixed(2);
                ot1Pay = (Number(ot1Pay) + Number(item.overtime_week_pay)).toFixed(2);
                ot2 = (Number(ot2) + Number(item.overtime_holiday)).toFixed(2);
                ot2Pay = (Number(ot2Pay) + Number(item.overtime_holiday_pay)).toFixed(2);
                total = (Number(total) + Number(item.total)).toFixed(2);

                return null;
            });

            return null;
        });

        return [hours, reg, `$ ${regPay}`, ot1, `$ ${ot1Pay}`, ot2, `$ ${ot2Pay}`, `$ ${total}`];
    }

    const getTotals = () => {
        let hours = 0, regular_hours = 0, regular_hours_pay = 0, overtime_hours = 0, overtime_hours_pay = 0;

        state.subheadersM?.map(subheader => {
            hours = (Number(hours) + Number(subheader.hours)).toFixed(2);
            regular_hours = (Number(regular_hours) + Number(subheader.regular_hours)).toFixed(2);
            regular_hours_pay = (Number(regular_hours_pay) + Number(subheader.regular_hours_pay)).toFixed(2);
            overtime_hours = (Number(overtime_hours) + Number(subheader.overtime_hours)).toFixed(2);
            overtime_hours_pay = (Number(overtime_hours_pay) + Number(subheader.overtime_hours_pay)).toFixed(2);

            return false;
        });

        state.subheadersNM?.map(subheader => {
            subheader?.detail?.map(item => {
                hours = (Number(hours) + Number(item.hours)).toFixed(2);
                regular_hours = (Number(regular_hours) + Number(item.regular_hours)).toFixed(2);
                regular_hours_pay = (Number(regular_hours_pay) + Number(item.regular_hours_pay)).toFixed(2);
                overtime_hours = (Number(overtime_hours) + Number(item.overtime_week)).toFixed(2);
                overtime_hours_pay = (Number(overtime_hours_pay) + Number(item.overtime_week_pay)).toFixed(2);

                return null;
            });

            return null;
        });

        return [hours, regular_hours, `$ ${regular_hours_pay}`, overtime_hours, `$ ${overtime_hours_pay}`, `$ ${state.header.total}`]
    }

    const getProduct = txt => {
        const limit = 32;
        const cont = txt.length;

        return (cont > limit) ? txt.substr(0, limit - 3).concat('...') : txt.substr(0, limit);
    }

    if (state.loading) {
        return <Loader fullScreen />;
    }
    if (!state.permissions.read_attr || !state.header.idemployee_pay_header) {
        return <NotFound />;
    }

    return (
        <div className={classes.root}>
            <Divider className={classes.divider} />

            <Grid container>
                <Grid item xs={6} md={3} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Sede'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                placeholder='Sede'
                                value={state.header?.sede?.name ?? ''}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3} lg={3}>
                    <FormGroup>
                        <FormLabel>{'Week'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                placeholder='Week'
                                value={state.header.week}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={4} lg={6}>
                    <FormGroup>
                        <FormLabel>{'Employee'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                placeholder='Employee'
                                value={state.header.employee?.name}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Base rate'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                value={`$ ${state.header.base_rate}`}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Hours'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                value={state.header.hours}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Overtime base rate'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                value={`$ ${state.header.overtime_base_rate}`}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                <Grid item xs={6} md={3} lg={2}>
                    <FormGroup>
                        <FormLabel>{'Bonus'}</FormLabel>
                        <FormControl
                            variant='filled'
                            component={Box}
                            width='100%'
                        >
                            <FilledInput
                                disabled
                                type='text'
                                value={`$ ${state.header.bonus}`}
                            />
                        </FormControl>
                    </FormGroup>
                </Grid>
                {
                    state.permissions?.delete_attr &&
                    <Grid item xs={6} md={3} lg={2} className={classes.buttonContainer}>
                        <Button
                            variant='contained'
                            className={classes.delete}
                            onClick={destroy}
                        >
                            {'Delete'}
                        </Button>
                    </Grid>
                }
            </Grid>

            <Divider className={classes.divider} />
            <TableContainer component={Box}>
                <Table size='small' className={classes.totalTable}>
                    <TableHead>
                        <TableRow>
                            <Total title='Total hours' />
                            <Total title='Regular hours' />
                            <Total title='Regular hours $' />
                            <Total title='OT hours' />
                            <Total title='OT hours $' />
                            <Total title='Total paid' />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow >
                            {
                                getTotals().map((item, i) => <Total result key={i.toString()} title={item} />)
                            }
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Divider className={classes.divider} />
            <div>
                <h2>{'Payment (M)'}</h2>
                {
                    state.subheadersM.map(subheader => {
                        return (
                            <div key={subheader.idemployee_pay_subheader}>
                                <div className={classes.tableHeader}>
                                    <span><b>{'Date: '}</b>{dayjs(subheader.date).format('MM/DD/YYYY')}</span>
                                    <span><b>{'Regular hours: '}</b>{subheader.regular_hours}</span>
                                    <span><b>{'OT hours: '}</b>{subheader.overtime_hours}</span>
                                    <span><b>{'Hours break: '}</b>{subheader.hours_break}</span>
                                </div>

                                <TableContainer component={Box}>
                                    <Table size='small' className={classes.detail}>
                                        <TableHead>
                                            <TableRow>
                                                <Text title='Position' />
                                                <Text title='Product code' />
                                                <Text title='Hours pay' color='#DDF5FD' />
                                                {
                                                    Boolean(Number(state.header.overtime_base_rate)) &&
                                                    <Text title='OT pay' color='#ECCEF5' />
                                                }
                                                <Text title='Product rate' />
                                                <Text title='Total qty' />
                                                <Text title='Base pay' color='#DDF5FD' />
                                                <Text title='Real pay' color='#EDFBDB' />
                                                <Text title='Bonus' />
                                                <Text title='Subsidy' />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                subheader.detail.map(item =>
                                                    <TableRow key={item.idemployee_pay}>
                                                        <Text title={item.position} />
                                                        <Text title={getProduct(`${item.product.code} - ${item.product.description}`)} />
                                                        <Text title={item.hours_break !== '0.00' ? `- $ ${(item.hours_break * state.header.base_rate).toFixed(2)} (${item.hours_break})` : '-'} color='#DDF5FD' />
                                                        {
                                                            Boolean(Number(state.header.overtime_base_rate)) &&
                                                            <Text title={`-`} color='#ECCEF5' />
                                                        }
                                                        <Text title={`$ ${item.product_rate}`} />
                                                        <Text title={item.total_qty} />
                                                        <Text title={`$ ${item.base_pay}`} color='#DDF5FD' />
                                                        <Text title={`-`} color='#EDFBDB' />
                                                        <Text title={`-`} />
                                                        <Text title={`-`} />
                                                    </TableRow>
                                                )
                                            }
                                            <TableRow className={classes.total}>
                                                <TableCell align='center'>{'Totals:'}</TableCell>
                                                <TableCell colSpan={1} />
                                                <Text title={<b>{`$ ${subheader.regular_hours_pay}`}</b>} color='#DDF5FD' />
                                                {
                                                    Boolean(Number(state.header.overtime_base_rate)) &&
                                                    <Text title={`$ ${subheader.overtime_hours_pay}`} color='#ECCEF5' />
                                                }
                                                <TableCell colSpan={1} />
                                                <TableCell colSpan={1} />
                                                <Text title={<b>{`$ ${subheader.base_pay}`}</b>} color='#DDF5FD' />
                                                <Text title={<b>{`$ ${subheader.real_pay}`}</b>} color='#EDFBDB' />
                                                <Text title={<b>{`$ ${subheader.bonus}`}</b>} />
                                                <Text title={<b>{`$ ${subheader.subsidy}`}</b>} />
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        );
                    })
                }
                <TableContainer component={Box}>
                    <Table size='small' className={classes.footer}>
                        <TableHead>
                            <TableRow>
                                <Total title='Total hours' />
                                <Total title='Regular hours' />
                                <Total title='Regular hours $' />
                                <Total title='OT hours' />
                                <Total title='OT hours $' />
                                <Total title='Total piece qty' />
                                <Total title='Piece pay' />
                                <Total title='Bonus' />
                                <Total title='Subsidy' />
                                <Total title='Total paid' />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow >
                                {
                                    getTotalM(state.subheadersM).map((item, i) => <Total result key={i.toString()} title={item} />)
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <Divider className={classes.divider} />
            <div>
                <h2>{'Payment (NM)'}</h2>
                {
                    state.subheadersNM.map(subheader => {
                        return (
                            <div key={subheader.idemployee_pay_subheader_nm}>
                                <div className={classes.tableHeader}>
                                    <span><b>{'Total hours: '}</b>{subheader.hours}</span>
                                    <span><b>{'Total paid: '}</b>{`$ ${subheader.total}`}</span>
                                </div>
                                <TableContainer component={Box}>
                                    <Table size='small' className={classes.detail}>
                                        <TableHead>
                                            <TableRow>
                                                <Text title='Date' />
                                                <Text title='Total hours' />
                                                <Text title='Regular hours' />
                                                <Text title='Regular hours $' />
                                                <Text title='OT hours' />
                                                <Text title='OT hours $' />
                                                <Text title='Holiday hours' />
                                                <Text title='Holiday hours $' />
                                                <Text title='Total paid' />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                subheader.detail.map(item =>
                                                    <TableRow key={item.idemployee_pay_nm}>
                                                        <Text title={dayjs(item.date).format('MM/DD/YYYY')} />
                                                        <Text title={item.hours} />
                                                        <Text title={item.regular_hours} />
                                                        <Text title={`$ ${item.regular_hours_pay}`} />
                                                        <Text title={item.overtime_week} />
                                                        <Text title={`$ ${item.overtime_week_pay}`} />
                                                        <Text title={item.overtime_holiday} />
                                                        <Text title={`$ ${item.overtime_holiday_pay}`} />
                                                        <Text title={`$ ${item.total}`} />
                                                    </TableRow>
                                                )
                                            }
                                            <TableRow className={classes.total}>
                                                <TableCell align='center'>{'Totals:'}</TableCell>
                                                <Text title={<b>{subheader.hours}</b>} />
                                                <Text title={<b>{subheader.regular_hours}</b>} />
                                                <Text title={<b>{`$ ${subheader.regular_hours_pay}`}</b>} />
                                                <Text title={<b>{subheader.overtime_week}</b>} />
                                                <Text title={<b>{`$ ${subheader.overtime_week_pay}`}</b>} />
                                                <Text title={<b>{subheader.overtime_holiday}</b>} />
                                                <Text title={<b>{`$ ${subheader.overtime_holiday_pay}`}</b>} />
                                                <Text title={<b>{`$ ${subheader.total}`}</b>} />
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        );
                    })
                }
                <TableContainer component={Box}>
                    <Table size='small' className={classes.footer}>
                        <TableHead>
                            <TableRow>
                                <Total title='Total hours' />
                                <Total title='Regular hours' />
                                <Total title='Regular hours $' />
                                <Total title='OT hours' />
                                <Total title='OT hours $' />
                                <Total title='Holiday hours' />
                                <Total title='Holiday hours $' />
                                <Total title='Total paid' />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow >
                                {
                                    getTotalsNM(state.subheadersNM).map((item, i) => <Total result key={i.toString()} title={item} />)
                                }
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        </div >
    );
}

export default EmployeePayDetail;